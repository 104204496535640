import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useNavigate } from "react-router-dom"

import useUserCheckEmail from './../hooks/useUserCheckEmail';

function CheckEmail () {
  const [loginForm, setLoginForm] = useState({token: ''})

  function handleChange (event:any) {
    const name = event.target.name
    const value = event.target.value
    setLoginForm(values => ({ ...values, [name]: value }))
  }
  //const connectionToken = useQuery('userId', connection.getConnectionToken)
  const userCheckEmailMutation = useUserCheckEmail()
  //const connectionQuery = useLogin()
  const navigate = useNavigate();
  const userQuery = useQuery(['user'])
  const connectionTokenQuery = useQuery(['connectionToken'])
  
  function handleSubmit (event) {
    event.preventDefault()
    console.log('submit')
    userCheckEmailMutation.mutate({
      token: loginForm.token,
      _id: userQuery.data._id
    })
  }

  if (connectionTokenQuery?.data?.connectionToken ) {
     navigate('/')
  }

  return (
    <div>
      <div className='title'>
        <h1>
          Links
        </h1>
      </div>
      <div className='loginForm'>
        <form onSubmit={handleSubmit}>
          <div className='line'>
        <input id="toekn" type="text" name="token" value={loginForm.token} placeholder="123456" onChange={handleChange}/>
          </div>
        <input type="submit" id="connectBtn" className="epiqueButton" value="Log in"/>
        </form>
      </div>

    </div>
  )
}

export default CheckEmail


