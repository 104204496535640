import React, {useState} from 'react'
import ColorSelector from './ColorSelector'
import './Labels.css'
import { useMutation } from 'react-query';
import useGetLabels from '../hooks/useGetLabels';
import { isLabeledStatement } from 'typescript';
import OutsideClickHandler from 'react-outside-click-handler'

function AddLabelButton({onClick}) {
  return (
    <div>
      <button className='Label-button-add umami--click--addTag' onClick={onClick}>
        <img src="/icons8_add_tag.svg" alt="add a label" height={12} />
        <span className='Label-add-Text'>Add a tag</span></button>
    </div>
  )
}

function AddLabelInput({onNewLabel, onClose}) {
  const [newLabel, setNewLabel] = useState({ color: "#6050dc", name: "" })
  const labels = useGetLabels()
var newLabelAlreadyExist = false

  function isLabelAlreadyExist(newLabelName) {
      labels.data.map((label) => {
        if (label.name === newLabelName) {
          return true
        }
      })
      return false
  }
  
  function onSelectColor(color) {
    setNewLabel({
      ...newLabel,
      color: color
    })
    
  }

  function handleSubmit(event) {  
    event.preventDefault()
    onNewLabel(newLabel)
  }


  function changeName(event) {
    setNewLabel({
      ...newLabel,
      name: event.target.value
    })
    // if (isLabelAlreadyExist(event.target.value)) {
    //   newLabelAlreadyExist = false
    // }
  }
  // console.log('label from AddLabelInut', labels)
  if (labels.status==='loading') return
  return (
    <div className='labels-AddLabel-container'>
      <div className='labels-AddLabel-Existing'>
        {labels.data.map((label) => {
         
          if (newLabel.name === label.name) {
            //  console.log('newLabel.name', newLabel.name)
            //   console.log('label.name', label.name)
            return (
              <Label
              name={label.name}
              color={label.color}
              key={label._id}
              onPress={() => { onNewLabel(label) }}
              deletable={false}
              border={true}
              />
            )
          }
          return (
            <Label
              name={label.name}
              color={label.color}
              key={label._id}
              onPress={() => { onNewLabel(label) }}
              deletable={false}
            border={false}/>
          )
        })}
      </div>
      
      <form onSubmit={handleSubmit} className="label-AddLabel-Form">
        <div className='label-AddLabel-NewLabel-text'>
          New tag name
        </div>
        <div className='label-AddLabel-line'>
        <input type="text" id="addLabelInput" className='addLabelInput' name="addLabelInput"  onChange={changeName} autoComplete="off"/>
        <button type="submit" className='label-AddLabel-button umami--click--saveNewTag'>
          <img src="/icons8_checkmark.svg" height={10} alt="validate" className='label-Add-button'/>
        </button>
        <button type="button" className='label-AddLabel-button' onClick={onClose}>
          <img src="/icons8_delete.svg" height={10} alt="cancel" className='label-AddLabel-button umami--click--cancelNewTag'/>
          </button>
        </div>
        <div className='label-AddLabel-line'>
          <ColorSelector onSelect={onSelectColor} selectedColor={newLabel.color}/>
        </div>
      </form>
      </div>
  )
}

function getColorByBgColor(bgColor) {
    if (!bgColor) { return ''; }
    return (parseInt(bgColor.replace('#', ''), 16) > 0xFFFFFF / 2) ? '#000' : '#fff';
  }


function Label({ name, color, onDelete, deletable, onPress, border }: { name: string, color: string, onDelete?: any, deletable?: boolean, onPress?: any, border?: boolean }) {

  if (border) {
    color='red'
  }
  
  // let textColor = getColorByBgColor(color)
  let textColor ='black'

  return (
    <div className='label-label-container'>
      <div className='label-label-rectangle' style={{ "backgroundColor": color }}>
      <button type="button" onClick={onPress} className='label-label-button umami--click--tag' >
        <span className='label-label-text' style={{color:textColor}}>{name}</span>
          
      </button>
      {
            deletable && (<span><button type="button" className='label-label-deleteButton umami--click--deleteTag' onClick={onDelete}>
            <img src="/icons8_delete_red.svg" height={10} />
            </button></span>)
        }
        </div>
    </div>
  )
}


function Labels({labels, addLabel, linkId, onRemove, onSelectLabel}: {labels: any, addLabel: any, linkId?: string, onRemove: any, onSelectLabel: any}) {
  const [displayAddLabel, setDisplayAddLabel] = useState(false)
  // console.log('labels', labels)
  function onAdd(label) {
    setDisplayAddLabel(false)
    addLabel(label)
  }

  function onClose() {
    setDisplayAddLabel(false)
  }
  function deleteLabel(labelId) {
  onRemove(labelId)
}

  return (
    <>
    <div className='label-listLabels'>
      {labels.map((label) => {
        return (<Label
          name={label.name}
          color={label.color}
          onDelete={() => { deleteLabel(label._id) }}
          deletable={true}
          onPress={() => {
            onSelectLabel(label.userLabelId)
          }
          }
          key={label._id} />)
      })}
      </div>
      <div>
        <AddLabelButton onClick={() => {
          // console.log('click')
          setDisplayAddLabel(!displayAddLabel)
        }} />
      {displayAddLabel && <AddLabelInput onNewLabel={onAdd} onClose={onClose} />}
    </div>
    </>
  )
}


export default Labels